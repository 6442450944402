@charset "UTF-8";

.site-header {
  border-bottom: 1px solid #e8e8e8;
  min-height: 56px;
  background-color: $secondary;
  padding-top: 5px;
}

.site-title,
.site-title:hover,
.site-title:visited {
  display: block;
  color: #333;
  font-size: 26px;
  letter-spacing: -1px;
  float: left;
  position: relative;
  z-index: 1;
}

.site-nav {
  float: right;
  line-height: 56px;

  .menu-icon {
      display: none;
  }

  .page-link {
    margin-left: 20px;
    color: white;
    letter-spacing: -.5px;
  }

    @media screen and (max-width: $break-medium) {

        position: fixed;
        z-index: 10;
        top: 14px; right: 8px;
        background-color: $secondary;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;

            .menu-icon {
              display: block;
              font-size: 24px;
              color: #505050;
              float: right;
              width: 36px;
              text-align: center;
              line-height: 36px;
            }

            .menu-icon svg { width: 18px; height: 16px; }

            .trigger {
              clear: both;
              margin-bottom: 5px;
              display: none;
            }

            &:hover .trigger { display: block; }

            .page-link {
              display: block;
              text-align: right;
              line-height: 1.25;
              padding: 5px 10px;
              margin: 0;
            }
    }
}
