@charset "UTF-8";

.nuget-console-command {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: #202020;
  border: 4px solid silver;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #6e6e6e;
  color: #e2e2e2;
  display: block;
  font: 1.5em 'andale mono', 'lucida console', monospace;
  line-height: 1.5em;
  overflow: auto;
  padding: 15px;
}
