@use "sass:color";

$baseurl: "";
$break-biggest: 750px;
$break-medium: 600px;

$primary: #af231c;
$secondary: #666666;
$cold-compliment: #1da8af;
$cold-compliment_10: color.adjust($cold-compliment, $lightness: -10%);
$cold-compliment_20: color.adjust($cold-compliment, $lightness: -20%);
$cold-compliment_30: color.adjust($cold-compliment, $lightness: -30%);
$cold-compliment_40: color.adjust($cold-compliment, $lightness: -40%);
$cold-compliment_50: color.adjust($cold-compliment, $lightness: -50%);
$warm-compliment: #af921d;

@import "resets";
@import "wrap";
@import "site-header";
@import "footer";
@import "page-content";
@import "home";
@import "post";
@import "highlight";
@import "nuget";
@import "image-frame";
@import "custom-icons";
