@charset "UTF-8";

.home h1 { margin-bottom: 25px; }

.posts {
    list-style-type: none;

    li { margin-bottom: 30px; }

    .post-link {
      display:block;
      font-size: 24px;
      letter-spacing: -1px;
      line-height: 1;
    }

    .post-date {
      font-size: 15px;
      color: $secondary;
      padding-right:15px;
    }

    [class^="icon-"]:before,
    [class*=" icon-"]:before {
      font-size:15px;
      color: $secondary;
    }
}
