@charset "UTF-8";

.post-header {
    margin: 10px 0 30px;

    h1 {
      font-size: 42px;
      letter-spacing: -1.75px;
      line-height: 1;
      font-weight: 300;
    }

    .meta {
      font-size: 15px;
      color: $secondary;
      margin-top: 5px;
    }

    @media screen and (max-width: $break-medium) {

        h1 { font-size: 36px; }
    }
}

.post-content {
    margin: 0 0 30px;

    > * { margin: 20px 0; }

    h1, h2, h3, h4, h5, h6 {
      line-height: 1;
      font-weight: 300;
      margin: 40px 0 20px;
    }

    h2 {
      font-size: 32px;
      letter-spacing: -1.25px;
    }

    h3 {
      font-size: 26px;
      letter-spacing: -1px;
    }

    h4 {
      font-size: 20px;
      letter-spacing: -1px;
    }

    blockquote {
      border-left: 4px solid #e8e8e8;
      padding-left: 20px;
      font-size: 18px;
      opacity: .6;
      letter-spacing: -1px;
      font-style: italic;
      margin: 30px 0;
    }

    ul, ol {
        padding-left: 20px;
    }

    @media screen and (max-width: $break-medium) {

      h2 { font-size: 28px; }
      h3 { font-size: 22px; }
      h4 { font-size: 18px; }
      blockquote { padding-left: 10px; }
      ul,
      ol { padding-left: 10px; }
  }
}

.post
{
    pre, code {
        border: 1px solid #d5d5e9;
        background-color: #eef;
        padding: 8px 12px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        font-size: 15px;
        overflow:scroll;
    }

    code { padding: 1px 5px; }

    ul, ol { margin-left: 1.35em; }

    pre code {
      border: 0;
      padding-right: 0;
      padding-left: 0;
    }

    /* terminal stype */
    pre.terminal {
      border: 1px solid #000;
      background-color: #333;
      color: #FFF;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }

    pre.terminal code { background-color: #333; }
}
