@charset "UTF-8";


* {
  margin: 0;
  padding: 0;
}

html, body { height: 100%; }

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  background-color: #fdfdfd;
}

h1, h2, h3, h4, h5, h6 { font-size: 100%; font-weight: 400; }

a         { color: $cold-compliment_10; text-decoration: none; }
a:hover   { color: #000; text-decoration: underline; }
a:visited { color: $cold-compliment_30; }
